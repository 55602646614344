@charset "utf-8";

// First let's import all boilerplate code
@import "setup/vars";
@import "setup/mixins";
@import "setup/normalize";
@import "setup/helpers";
@import "setup/boilerplate";
@import "setup/burger";
//@import "setup/fonts";
@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&subset=hebrew&display=swap');

@keyframes float {
	0% {
		//box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		//box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		//box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

@keyframes fadeFromTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeFromBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes zoomFade {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  75% {
    opacity: 1;
    transform: scale(1.06);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
            
.float{
    animation: float 6s ease-in-out infinite;
    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;

}

.fadeFromTop{
    animation-name: fadeFromTop;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity:1;
    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;
}

.fadeFromBottom{
    animation-name: fadeFromBottom;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity:1;
    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;
}

.zoomFade{
    animation-name: zoomFade;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    opacity:1;
    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;
}

.mobile-slider{
    display:none!important;
}
.home{background: url(../img/bundle-pxl.png) 0 1020px repeat-x;}

.home-slider{
    display:block;
    width:100%;
    font-size:0;
    margin-bottom: 0 !important;
    
    .slick-next{
        left:50% !important;
        width: 60px!important;
        height:142px!important;
        top:191px !important;
        margin:0 !important;
        margin-left:-776px !important;
        background:rgba(255,255,255,0.2);
        opacity:1!important;
        @include transition(all 0.2s linear);

        &:before{
            content: "\f104" !important;
            color:white;
            position:relative;
            font-size:40px;
            font-family: 'FontAwesome';
            opacity:1!important;
            @include transition(all 0.2s linear);
        }
        
        &:hover{
            background:rgba(255,255,255,0.4)!important;
        }
        
        &:focus{
            background:rgba(255,255,255,0.2);
        }
    }
    
    .slick-prev{
        left:0 !important;
        right:50% !important;
        width: 60px!important;
        height:142px!important;
        top:191px !important;
        margin:0 !important;
        margin-right:-636px !important;
        background:rgba(255,255,255,0.2);
        opacity:1!important;
        @include transition(all 0.2s linear);
        z-index:5;
        
        &:before{
            content: "\f105" !important;
            color:white;
            position:relative;
            font-size:40px;
            font-family: 'FontAwesome';
            opacity:1!important;
            @include transition(all 0.2s linear);
        }
        
        &:hover{
            background:rgba(255,255,255,0.4)!important;
        }
        
        &:focus{
            background:rgba(255,255,255,0.2);
        }
    }
    
    .slick-dots{
        width:auto;
        bottom:0;
        top:520px;
        right:50%;
        
        li{
            margin-left:8px!important;
            padding-right:0!important;
            text-align: right;
            width:14px;
            
            button{
                &:before{
                    content:"";
                    background:white;
                    height:10px;
                    width:10px;
                    top:50%;
                    margin-top:-5px;
                    right:50%;
                    margin-right:-5px;
                    border-radius: 9999px;
                    right:0;
                    left:auto;
                    color:white;
                    @include transition(all 0.2s linear);
                }
            }
            
            &.slick-active{
                button{
                    &:before{
                        color:white;
                        height:16px;
                        width:16px;
                        margin-top:-8px;
                        margin-right:-8px;
                    }
                }
            }
        }
    }
    
    .slide{
        height:840px;
        background: url('') center bottom no-repeat;
        background-color:#d0d0d0;
        position:relative;
        overflow: hidden;
        outline: 0!important;
        
        video{
            position: absolute;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
            pointer-events: none;
        }
        
        &.slick-active{
            article{
                h2{
                    opacity:0;
                    @include transform(translateY(0px));
                }
            }
        }
        
        .animation-container{
            position:absolute;
            height:840px;
            width:1920px;
            top:50%;
            left:50%;
            margin-left: -960px;
            margin-top: -420px;
            border: 0;
            pointer-events: none;
            
            .animation{
                position: relative;
                width:100%;
                height:100%;
                
                img{
                    opacity:0;
                }
            }
            
            .elem1{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: 510px;
                margin-top: 70px;
                
                img{
                    width: 275px;
                }
            }
            
            .elem2{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: 358px;
                margin-top: -62px;
            }
            
            .elem3{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: 657px;
                margin-top: -120px;
            }     
            
            .elem4{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: 29px;
                margin-top: 127px;
                
                img{
                    width: 275px;
                }
            }
            
            .orn1{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: 192px;
                margin-left: 483px;
                
                img{
                    width: 39px;
                    height: 39px;
                }
            }
            
            .orn2{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: 233px;
                margin-left: 649px;

                
                img{
                    width: 90px;
                }
            }
            
            .orn3{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: 159px;
                margin-left: 807px;
                
                img{
                    width: 28px;
                }
            }
            
        }
        
        article{
            position:absolute;
            width:700px;
            left:50%;
            margin-left: -700px;
            margin-left: -700px;
            top:100px;
            
            h2, .h2{
                font-size:70px;
                font-weight:$regular;
                color:white;
                margin:20px auto;
                line-height:1;
                opacity:0;
                @include transform(translateY(0px));
                @include transition(all 0.3s ease);
                p{
                    font-size: 70px;
                    font-weight: 400;
                    color: white;
                    line-height: 0.9;
                    margin-bottom:0;
                }
            }
            
            .bottom{
                opacity:0;
            }
            
            p{
                font-size:18px;
                font-weight:$regular;
                color:white;
                margin:20px auto;
                margin-bottom:50px;
                line-height:1.4;
                @include transition(all 0.3s ease);
            }
            
            .btn{
                margin-top:20px;
                font-size:18px;
                font-weight:$bold;
                color:white;
                text-decoration: none;
                padding:15px 25px;
                text-align: center;
                background:$light-blue;
                border-radius:4px;
                @include transition(all 0.3s ease, background 0.2s linear);
                
                &:hover{
                    background:lighten( $light-blue, 10% );
                }
            }
        }
    }
}

body.en{
    .home-slider{
        .slick-prev{
            left: auto!important;
            right: 50% !important;
        }
    }
}

.top-section-mobile{
    display:none!important;
}

.top-section{
    position:relative;
    display:block;
    width:1200px;
    margin: 0 auto;
    padding:0 24px;
    box-sizing: border-box;
    font-size:0;
    top:-70px;
    margin-bottom:-70px;
    
    .col{
        width:50%;
        min-height:400px;
        display:inline-block;
        background:white;
        @include box-shadow(0px, 2px, 34px, rgba(0, 0, 0, 0.12));
        padding:50px 140px;
        box-sizing: border-box;
        text-align: center;
        vertical-align: top;
        div { height: 110px;}
        .icon{
            height:84px;
            display:inline-block;
            object-fit: contain;
        }
        
        h2{
            font-size:70px;
            color:$light-blue;
            font-weight:$regular;
            margin:5px auto;
            margin-bottom:0;
        }
        
        h3{
            font-size:18px;
            color:$light-blue;
            font-weight:$medium;
            margin-top:-3px;
        }
        
        p{
            font-size:16px;
            color:$grey;
            line-height: 1.2;
        }
        
        .read-more{
            font-size:18px;
            color:$dark-blue;
            font-weight:$medium;
            text-decoration: none;
            position: relative;
            z-index:1;
            @include transition(all 0.3s ease);

            &:hover{
                color:lighten( $dark-blue, 10% );
            }
        }
    }
}

.white-fade{
    width: 100%;
    position: relative;
    pointer-events: none;
    height: 130px;
    border-bottom: 20px solid white;
    margin-top: -180px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
}

.size-section{
    /*width:100%;
    text-align: center;
    padding-bottom: 120px;
    background: linear-gradient(to top, rgb(244,247,249) 0%, rgba(244,247,249,0) 60%);*/
    background: url(../img/bundle-hp-bg.jpg);
    width: 1155px;
    margin: 0 auto;
    height: 383px;
    text-align: center;
    h2{
        p{
        font-size: 50px;
        color: #004d71;
        font-weight: 400;
        margin-bottom: 20px;
        padding-top: 30px;
        box-sizing: border-box;
        br{display:none;}
       }
    }
    
    p{
        font-size:18px;
        font-weight:$medium;
        color:$dark-blue;
        max-width: 720px;
        margin: 0 auto;
        box-sizing: border-box;
        margin-bottom: 35px;
    }
    .ruler {
        .workers{width: 730px; margin: 0 auto;
            .upper{width: 100%;
                .text{ width: 18%; display: inline-block; text-align: center; color:$light-blue;}
                .text:nth-child(2){ padding-right: 16px;  color: $dark-blue;}
                .text:nth-child(3){ width: 20%; padding-right: 24px;}
                .text:nth-child(4){ width: 20%; padding-right: 18px;}
                .text:nth-child(5){ width: 20%;}
                }
            }    
        .img { img {display: block;margin: 0 auto;}}  
        }
    .btn{
        margin-top:20px;
        font-size:18px;
        font-weight:$bold;
        color:white;
        text-decoration: none;
        padding:15px 25px;
        text-align: center;
        background:$light-blue;
        border-radius:4px;
        position:relative;
        top: 45px;
        @include transition(all 0.3s ease, background 0.2s linear);

        &:hover{
            background:lighten( $light-blue, 10% );
        }
    }  
}

.mobile-tools{
    display:none;
}

.tool{
    display:block;
    min-height:500px;
    background: linear-gradient(to top, rgb(244,247,249) 0%, rgba(244,247,249,0) 60%);
    font-size:0;
    
    &.mirrored{
        .tool-container{
            background-position: 5% 100%;
            
            .info{
                padding-right:0;
                padding-left: 120px;
            }
        }
    }
    
    .tool-container{
        max-width:1920px;
        margin:0 auto;
        text-align: center;
        background: url('') center bottom no-repeat;
        background-image: url(img/tool1.png);
        background-position: 100% 100%;
        
        .half{
            width:600px;
            display:inline-block;
            height:657px;
            vertical-align: top;
            text-align: right;
        }
        
        .quote{
            position:relative;
            
            article{
                position:absolute;
                bottom:0;
            }
            
            .symbol{
                width:48px;
                height:auto;
            }
            
            h2,.h2{
                color:white;
                font-size:42px;
                font-weight:$bold;
                margin:14px 0;
                text-shadow: 0 0 50px rgba(34, 61, 72, 0.4);                
               p{
                   color:white;
                    font-size:42px;
                    font-weight:$bold;
                    margin:14px 0;
                    text-shadow: 0 0 50px rgba(34, 61, 72, 0.4);
                    margin:0;
                }
            }

            .signature{
                height:auto;
                max-height: 40px;
            }
            
            .cred{
                color:white;
                font-size:14.5px;
                font-style: italic;
                font-weight:$regular;
                margin:20px 0;
                text-shadow: 0 0 40px rgba(34, 61, 72, 0.4);
            }
        }
        
        .info{
            box-sizing: border-box;
            padding-right: 120px;
            
            img{
                width:94px;
                height:auto;
                margin-top:180px;
            }
            
            h2{
                color: $light-blue;
                font-weight: $regular;
                font-size:50px;
                margin: 20px 0;
                p{
                    color: $light-blue;
                    font-weight: $regular;
                    font-size:42px!important;
                    margin: 20px 0;
                }
            }
            
            p{
                color:$grey;
                font-size:20px;
                font-weight:$regular;
                max-width:400px;
                line-height:1.5;
            }
            
            .more-info{
                color:$dark-blue;
                font-size:18px;
                font-weight:$bold; 
                @include transition(all 0.3s ease);

                &:hover{
                    color:lighten( $dark-blue, 10% );
                }
            }
        }
        
        
        
    }

}

.experts-section{
    text-align: center;
    margin-bottom: 70px;
    
    .btn{
        display:none;
    }
    
    .title{
        color:$dark-blue;
        font-size:50px;
        font-weight: $regular;
    }
    
    .items-slider{
        display:none;
    }
    
    .items-container{
        width:100%;
        margin: 0 auto;
        max-width:1200px;
        text-align: right;
        font-size: 0;
        
        .featured-item{
            width:100%;
            height:462px;
            background: url('') center center no-repeat #dedede;
            background-size: cover;
            padding:30px;
            box-sizing: border-box;
            
            .info{
                background:rgba(255,255,255,0.9);
                float:left;
                width:580px;
                height:100%;
                text-align: right;
                padding:55px;
                box-sizing: border-box;
                
                .date{
                    margin-top:10px;
                    color:$grey;
                    font-size:14px;
                    font-weight:bold;
                }
                
                h2{
                    font-size:40px;
                    color:$light-blue;
                    font-weight:$regular;
                    text-decoration: none;
                    line-height:1;
                    margin:15px 0;
                    line-height: 1;
                    p{
                        font-size:40px;
                        color:$light-blue;
                        font-weight:$regular;
                        text-decoration: none;
                        line-height:1;
                        margin:15px 0;
                        line-height: 1;
                    }
                }
                
                p{
                    font-weight: $light;
                    color: $grey;
                    font-size: 16px;
                    line-height: 1.5;
                    margin:15px 0;
                }
                
                .category{
                    font-size:18px;
                    font-weight:$medium;
                    color: $dark-blue;
                    text-decoration: none;
                    @include transition(all 0.3s ease); 

                    &:hover{
                        color:lighten( $dark-blue, 10% );
                    }
                }
            }
        }
                
        .item{
            width:595px;
            margin-left:5px;
            height:290px;
            margin-top:40px;
            display: inline-block;
            font-size:0;
            
            .poster{
                width:285px;
                background-color:#dedede;
                height:100%;
                background: url('') center center no-repeat #dedede;
                background-size: cover;
                display: inline-block;
                vertical-align: middle;
                position: relative;
            }
            
            &.video{
                .poster{
                    &:after{
                        content: "";
                        position: absolute;
                        top:0;
                        right:0;
                        width:100%;
                        height:100%;
                        opacity:0.8;
                        background:url('../img/play.png') center center no-repeat;
                    }  
                }
            }
            .info{
                width:310px;
                height:100%;
                padding: 20px;
                box-sizing: border-box;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                
                &:before{
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 20px;
                    width: 122px;
                    height: 5px;
                    @include linear-gradient(to right, #76c5d7 0%, #3596b5 100%);
                }
                
                .date{
                    margin-top:10px;
                    color:$grey;
                    font-size:14px;
                    font-weight:bold;
                }
                
                h2{
                    font-size:28px;
                    color:$light-blue;
                    font-weight:$regular;
                    text-decoration: none;
                    line-height:1;
                    margin:15px 0;
                    max-height: auto;
                    
                    p{
                        font-size: 28px;
                        color: #4296b4;
                        font-weight: 400;
                        text-decoration: none;
                        line-height: 1;
                        margin: 15px 0;
                        max-height: auto;
                    }
                }
                
                p{
                    font-weight: $light;
                    color: $grey;
                    font-size: 16px;
                    line-height: 1.5;
                    margin:15px 0;
                    max-height: 95px;
                }
                
                .category{
                    font-size:18px;
                    font-weight:$medium;
                    color: $dark-blue;
                    text-decoration: none;
                    @include transition(all 0.3s ease); 

                    &:hover{
                        color:lighten( $dark-blue, 10% );
                    }
                }
            }
        }
        
    }
}
@media screen and (max-width: 1500px) {
    .tool{
        
        &.mirrored{
            .tool-container{
                    background-position: -36% 100%;
            }
        }
        
        .tool-container{
            background-position: 210% 100%;
        }
    }
    
    .home-slider{
        .slide{
            video{
                right: -20%;
            }
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {

    .home-slider{
        display:none!important;
    }
    
    .mobile-slider{
        display:block!important;
        
        .slick-dots{
            bottom: 102px;
            
            li{
                margin-left:8px!important;
                padding-right:0!important;
                text-align: right;
                width:14px;

                button{
                    &:before{
                        content:"";
                        background:white;
                        top:50%;
                        right:50%;
                        height:16px;
                        width:16px;
                        margin-top:-8px;
                        margin-right:-8px;
                        border-radius: 9999px;
                        right:50%;
                        left:auto;
                        color:white;
                        @include transition(all 0.2s linear);
                    }
                }

                &.slick-active{
                    button{
                        &:before{
                            color:white;
                            height:16px;
                            width:16px;
                            margin-top:-8px;
                            margin-right:-8px;
                        }
                    }
                }
            }
        }
        
        .slide{
            height:840px;
            background: url('') center top no-repeat;
            background-color:#d0d0d0;
            position:relative;
            overflow: hidden;
            outline: 0!important;
            
            article{
                position:absolute;
                width:100%;
                padding:40px 50px;
                box-sizing: border-box;
                bottom:90px;
                right:0;
                
                h2{
                    font-size:54px;
                    font-weight:$regular;
                    color:white;
                    margin:20px auto;
                    line-height:1;
                    @include transform(translateY(0px));
                    @include transition(all 0.3s ease);
                     p{
                        font-size: 54px;
                        font-weight: $regular;
                        color: white;
                        line-height: 1.1;
                        margin-bottom:0;
                    }
                }

                .bottom{
                }

                p{
                    font-size:24px;
                    font-weight:$regular;
                    color:white;
                    margin:20px auto;
                    margin-bottom:20px;
                    line-height:1.1;
                    @include transition(all 0.3s ease);
                }

                .btn{
                    margin-top:0px;
                    font-size:28px;
                    font-weight:$bold;
                    width:auto;
                    color:white;
                    text-decoration: none;
                    padding:25px 55px;
                    text-align: center;
                    display: inline-block;
                    background:$light-blue;
                    border-radius:6px;
                    margin-top: 0px;
                    margin-bottom: 30px;
                    @include transition(all 0.3s ease, background 0.2s linear);

                    &:hover{
                        background:lighten( $light-blue, 10% );
                    }
                }
            }
        }
    }
    
    .top-section{
        display: none;
    }
    
    .top-section-mobile{
        position:relative;
        display:block!important;
        margin: 0 auto;
        width:92%;
        background: white;
        box-sizing: border-box;
        font-size:0;
        top:-90px;
        margin-bottom:-70px;
        @include box-shadow(0px, 2px, 140px, rgba(0, 0, 0, 0.12));
        
        .slick-dots{
            bottom: 32px;
            
            li{
                margin-left:8px!important;
                padding-right:0!important;
                text-align: right;
                width:14px;

                button{
                    &:before{
                        content:"";
                        background:#656565;
                        top:50%;
                        right:50%;
                        height:16px;
                        width:16px;
                        margin-top:-8px;
                        margin-right:-8px;
                        border-radius: 9999px;
                        right:50%;
                        left:auto;
                        color:white;
                        @include transition(all 0.2s linear);
                    }
                }

                &.slick-active{
                    button{
                        &:before{
                            color:white;
                            height:16px;
                            width:16px;
                            margin-top:-8px;
                            margin-right:-8px;
                        }
                    }
                }
            }
        }
        
        .col{
            width:100%;
            min-height:520px;
            display:inline-block;
            background: white;
            padding: 50px 78px;
            padding-bottom: 90px;
            box-sizing: border-box;
            text-align: center;
            vertical-align: top;
            outline: 0!important;
            div { height: 183px;}
            .icon{
                height: 134px;
                display: inline-block;
                object-fit: contain;
            }

            h2{
                font-size: 110px;
                color:$light-blue;
                font-weight:$regular;
                margin:5px auto;
                margin-bottom:0;
            }

            h3{
                font-size:34px;
                color:$light-blue;
                font-weight:$medium;
                margin-top: -11px;
            }

            p{
                font-size:20px;
                color:$grey;
                line-height: 1.2;
                margin-top: -10px;
            }

            .read-more{
                font-size:21px;
                color:$dark-blue;
                font-weight:$medium;
                text-decoration: none;
                @include transition(all 0.3s ease);

            }
        }
    }
    
    .white-fade{
        display: none;
    }
    

    .desktop-tools{
        display:none;
    }
    
    .tool{
        display: none;
    }
    
    .mobile-tools{
        display:block;
        
        .tool{
            display: block;
            
            .tool-container{
                position: relative;
                background:none!important;
                .half{
                    width: auto;
                    display: block;
                    height: 420px;
                    vertical-align: top;
                    text-align: right;
                }
                .quote{
                    background: url('') center center no-repeat;
                    background-size:cover;
                    padding:40px;
                    padding-top:0;
                    box-sizing: border-box;
                    
                    article{
                        padding-left: 40px;
                    }
                    
                    h2{
                        font-size:34px;
                        p{ font-size:34px;}
                        br{
                            display: none;
                        }
                    }
                    
                    .h2{
                        font-size:34px;
                        p{ font-size:34px;}
                        br{
                            display: none;
                        }
                    }
                    
                    .cred{
                        font-size: 20px;
                    }
                    
                    &:before{
                        content: "";
                        position: absolute;
                        width:100%;
                        height:100%;
                        top:0;
                        right:0;
                        opacity:0.6;
                        background: -moz-linear-gradient(top, rgba(125,185,232,0) 15%, rgba(125,185,232,0.13) 26%, rgba(0,77,113,1) 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(top, rgba(125,185,232,0) 15%,rgba(125,185,232,0.13) 26%,rgba(0,77,113,1) 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom, rgba(125,185,232,0) 15%,rgba(125,185,232,0.13) 26%,rgba(0,77,113,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#004d71',GradientType=0 ); /* IE6-9 */
                    }
                }
                
                .info{
                    height:auto;
                    padding: 50px;
                    padding-top: 20px;
                    
                    img{
                        margin-top: 0;
                        display: inline-block;
                        margin-left: 30px;
                    }
                    
                    h2{
                        display: inline-block;
                        box-sizing: border-box;
                        vertical-align: middle;
                        max-width: 75%;
                    }
                    
                    p{
                        max-width:100%;
                        font-size: 24px;
                        
                        br{
                            display: none;
                        }
                    }
                    
                    .more-info{
                        font-size: 24px;
                    }
                }
            }
        }
    }
    
}
@media screen and (max-width: 640px)
{ 
    .size-section{
        margin-top: -170px; width: 100%;padding-top: 70px; padding-bottom: 120px;background: url(../img/bg-section-m.png); height: auto;
        .ruler { padding-right: 30px; box-sizing: border-box;
            .left { vertical-align: top; width: 70%;  display: inline-block; text-align: right;
                .inner{padding-bottom: 32px;vertical-align: top;color: #4296b4;font-size: 26px; color:$light-blue;}
                .inner:nth-child(3){color: $dark-blue;}
            }
            .img { width: 70px; padding-right: 0; box-sizing: border-box; display: inline-block;                
                  img {display: block;margin: 0 auto; width:80%;}
            }
        }
        p{
            max-width: 100%;
            width: 92%;
            padding: 0 50px;
            font-size: 24px;
            font-weight: $regular;
        }
        
        h2{
            margin-top: 0;
        }
        
        .btn{
            padding: 25px 50px;
            font-size:21px;
        }
    }

    .experts-section{
        text-align: center;
        margin-bottom: 25px;
    
        .title{
            color:$dark-blue;
            font-size: 40px;
            text-align: right;
            padding: 0 30px;
            padding-right:70px;
            box-sizing: border-box;
            font-weight: $regular;
            margin-bottom: 10px;
        }
        
        .slick-dots{
            bottom: -35px;
            
            li{
                button{
                    &:before{
                        content: "•";
                        width: 30px;
                        height: 30px;
                        font-size: 15px;
                        line-height: 30px;
                        color: #646567;
                    }
                }
                
                &.slick-active{
                    button{
                        &:before{
                            color: #646567;
                        }
                    }
                }
            }
        }
        .items-container{
            display:none;
        }
        
        .items-slider{
            display:block;
            width:100%;
            margin: 0 auto;
            max-width:1200px;
            text-align: right;
            font-size: 0;
            
            
                .item{
                    width:90%;
                    margin-left:5px;
                    height:auto;
                    margin-top:0;
                    display: inline-block;
                    font-size:0;
                    margin: 20px 10px;

                    @include box-shadow(0px, 2px, 40px, rgba(0, 0, 0, 0.12));

                    .poster{
                        width:100%;
                        background-color:#dedede;
                        height:400px;
                        background: url('') center center no-repeat #dedede;
                        background-size: cover;
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;
                    }

                    &.video{
                        .poster{
                            &:after{
                                content: "";
                                position: absolute;
                                top:0;
                                right:0;
                                width:100%;
                                height:100%;
                                opacity:0.8;
                                background:url('../img/play.png') center center no-repeat;
                            }  
                        }
                    }
                    
                    .info{
                        width:100%;
                        height:280px;
                        padding: 20px;
                        box-sizing: border-box;
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;

                        .date{
                            margin-top:10px;
                            color:$grey;
                            font-size:21px;
                            font-weight:medium;
                        }

                        h2{
                            font-size: 32px;
                            color:$light-blue;
                            font-weight:$regular;
                            text-decoration: none;
                            line-height:1;
                            margin:15px 0;
                            max-height:128px;
                        }

                        p{
                            font-weight: $light;
                            color: $grey;
                            font-size: 24px;
                            line-height: 1.5;
                            margin:15px 0;
                            height:80px;
                        }

                        .category{
                            font-size:21px;
                            font-weight:$medium;
                            color: $dark-blue;
                            text-decoration: none;
                            @include transition(all 0.3s ease); 

                            &:hover{
                                color:lighten( $dark-blue, 10% );
                            }
                        }
                    }
                }
            }
        
            .btn{
                display: block;
                margin: 0 auto;
                margin-top:80px;
                font-weight:$medium;
                color:white;
                text-decoration: none;
                padding: 25px 50px;
                font-size:24px;
                max-width: 300px;
                text-align: center;
                background:$light-blue;
                border-radius:4px;
                @include transition(all 0.3s ease, background 0.2s linear);
            } 
        }
}
.en{
    .top-section .col{min-height: 520px;padding: 50px 120px;}
    .top-section .col div { height: 160px; }
    .tool .tool-container .half {text-align: left;}
    .tool.mirrored .tool-container .info { padding-left: 260px;}
    .experts-section .items-container .featured-item .info {text-align: left;padding: 55px;box-sizing: border-box;}
    .size-section .ruler .workers .upper .text:nth-child(2) {padding-right: 16px; padding-left: 34px;}
    .size-section .ruler .workers .upper .text:nth-child(3) {padding-right: 24px; padding-left: 54px;}
    .size-section .ruler .workers .upper .text:nth-child(4) {padding-right: 18px; padding-left: 38px;}
    @media screen and (max-width: $mobile-breakpoint) { 
        .size-section .ruler .left {text-align: left;}
        .mobile-tools .tool .tool-container .info img {margin-left: 0;}
        .tool .tool-container .info h2 {margin: 20px 0 0 20px;}
        .experts-section .title {text-align: left; padding-left: 70px;padding-right:unset;}
        .experts-section .items-slider .item .info{text-align:left;}
    }
}